<template>
  <!-- Page Content  -->
  <b-container fluid>
    <b-row>
      <!-- BEGIN:Body -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Aktivasi Billing Beasiswa 1-1</h4>
          </template>
          <template v-slot:body>
            <!-- BEGIN:Form -->
            <div class="align-items-center p-2 mt-3">
              <b-form>
                <b-form-group
                  class="row pt-2"
                  label-cols-sm="2"
                  label="Nomor Billing"
                  label-for="nobilling"
                >
                  <input-primary
                    id="nobilling"
                    v-model="form.nobilling"
                    @input="searchData"
                    required
                    type="number"
                    max-length="20"
                  />
                </b-form-group>
                <!-- BEGIN:Data Mahasiswa -->
                <fieldset class="p-3 border">
                  <legend>Data Mahasiswa</legend>
                  <b-form-group
                    class="row"
                    label-cols-sm="2"
                    label="NIM"
                    label-for="nim"
                  >
                    <input-primary
                      id="nim"
                      v-model="form.nim"
                      required
                      isDetail
                      type="number"
                      max-length="9"
                    />
                  </b-form-group>
                  <b-form-group
                    class="row pt-2"
                    label-cols-sm="2"
                    label="Nama Mahasiswa"
                    label-for="namaMahasiswa"
                  >
                    <input-primary
                      id="namaMahasiswa"
                      v-model="form.namaMahasiswa"
                      required
                      isDetail
                    />
                  </b-form-group>
                  <b-form-group
                    class="row pt-2"
                    label-cols-sm="2"
                    label="UPBJJ-UT"
                    label-for="upbjj"
                  >
                    <input-primary
                      id="upbjj"
                      v-model="form.upbjj"
                      required
                      isDetail
                    />
                  </b-form-group>
                  <b-form-group
                    class="row pt-2"
                    label-cols-sm="2"
                    label="Program Studi"
                    label-for="programStudi"
                  >
                    <input-primary
                      id="programStudi"
                      v-model="form.programStudi"
                      required
                      isDetail
                    />
                  </b-form-group>
                  <b-form-group
                    class="row pt-2"
                    label-cols-sm="2"
                    label="Masa Registrasi Awal"
                    label-for="masaRegistrasiAwal"
                  >
                    <input-primary
                      id="masaRegistrasiAwal"
                      v-model="form.masaRegistrasiAwal"
                      required
                      isDetail
                    />
                  </b-form-group>
                  <b-form-group
                    class="row pt-2"
                    label-cols-sm="2"
                    label="Masa Registrasi Akhir"
                    label-for="masaRegistrasiAkhir"
                  >
                    <input-primary
                      id="masaRegistrasiAkhir"
                      v-model="form.masaRegistrasiAkhir"
                      required
                      isDetail
                    />
                  </b-form-group>
                  <b-form-group
                    class="row pt-2"
                    label-cols-sm="2"
                    label="Pendidikan Akhir"
                    label-for="pendidikanAkhir"
                  >
                    <input-primary
                      id="pendidikanAkhir"
                      v-model="form.pendidikanAkhir"
                      required
                      isDetail
                    />
                  </b-form-group>
                  <b-form-group
                    class="row pt-2"
                    label-cols-sm="2"
                    label="Status Data Pribadi"
                    label-for="statusDp"
                  >
                    <input-primary
                      id="statusDp"
                      v-model="form.statusDp"
                      required
                      isDetail
                    />
                  </b-form-group>
                </fieldset>
                <!-- ./END:Data Mahasiswa -->
                <!-- BEGIN:Data Billing -->
                <fieldset class="p-3 mt-4 border">
                  <legend>Data Billing</legend>
                  <b-row>
                    <b-col md="5">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label="Status Billing"
                        label-for="statusBilling"
                      >
                        <input-primary
                          id="statusBilling"
                          v-model="form.statusBilling"
                          required
                          isDetail
                        />
                      </b-form-group>
                      <b-form-group
                        class="row pt-2"
                        label-cols-sm="3"
                        label="Cabang Bank"
                        label-for="cabangBank"
                      >
                        <input-primary
                          id="cabangBank"
                          v-model="form.cabangBank"
                          required
                          isDetail
                        />
                      </b-form-group>
                      <b-form-group
                        class="row pt-2"
                        label-cols-sm="3"
                        label="Jumlah SKS"
                        label-for="totalSks"
                      >
                        <input-primary
                          id="totalSks"
                          v-model="form.totalSks"
                          required
                          isDetail
                        />
                      </b-form-group>
                      <b-form-group
                        class="row pt-2"
                        label-cols-sm="3"
                        label="Status Bayar"
                        label-for="ketStatusBank"
                      >
                        <input-primary
                          id="ketStatusBank"
                          v-model="form.ketStatusBank"
                          required
                          isDetail
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="7">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label="Metode Pembayaran"
                        label-for="namaBank"
                      >
                        <input-primary
                          id="namaBank"
                          v-model="form.namaBank"
                          required
                          isDetail
                        />
                      </b-form-group>
                      <b-form-group
                        class="row pt-2"
                        label-cols-sm="3"
                        label="Tagihan"
                        label-for="totalBayar"
                      >
                        <input-primary
                          id="totalBayar"
                          v-model="form.totalBayar"
                          required
                          isDetail
                        />
                      </b-form-group>
                      <b-form-group
                        class="row pt-2"
                        label-cols-sm="3"
                        label="Tanggal Pembayaran"
                        label-for="tanggalSetor"
                      >
                        <input-primary
                          id="tanggalSetor"
                          v-model="form.tanggalSetor"
                          required
                          isDetail
                        />
                      </b-form-group>
                      <b-form-group
                        class="row pt-2"
                        label-cols-sm="3"
                        label="Jenis Tagihan"
                        label-for="keterangan"
                      >
                        <input-primary
                          id="keterangan"
                          v-model="form.keterangan"
                          required
                          isDetail
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </fieldset>
                <!-- ./END:Data Billing -->
                <!-- BEGIN:Form Data -->
                <fieldset class="px-4 pb-4 mt-4 border">
                  <legend>Form Data</legend>
                  <b-tabs
                    active-nav-item-class="iq-bg-dark"
                    no-fade
                    pills
                    justified
                  >
                    <!-- BEGIN:Kerjasama -->
                    <b-tab
                      title="Kerjasama"
                      title-link-class="font-weight-bold bg-light mx-2 mb-4"
                      active
                    >
                      <b-form class="px-4 pt-2">
                        <b-form-group
                          class="row pt-2"
                          label-cols-sm="2"
                          label="Program"
                          label-for="idJenisProgram"
                        >
                          <b-form-radio
                            inline
                            id="program1"
                            v-model="form.idJenisProgram"
                            value="1"
                            name="idJenisProgram"
                            disabled
                            >Pendas</b-form-radio
                          >
                          <b-form-radio
                            inline
                            id="program2"
                            v-model="form.idJenisProgram"
                            value="2"
                            name="idJenisProgram"
                            disabled
                            >Non Pendas</b-form-radio
                          >
                          <b-form-radio
                            inline
                            id="program3"
                            v-model="form.idJenisProgram"
                            value="3"
                            name="idJenisProgram"
                            disabled
                            >PPS</b-form-radio
                          >
                        </b-form-group>
                        <b-form-group
                          class="row pt-2"
                          label-cols-sm="2"
                          label="Masa"
                          label-for="masa"
                        >
                          <input-primary
                            id="masa"
                            v-model="form.masa"
                            required
                            type="number"
                            max-length="5"
                          />
                        </b-form-group>
                        <b-form-group
                          class="row pt-2"
                          label-cols-sm="2"
                          label="Kode PKS"
                          label-for="kodePks"
                        >
                          <input-datalists
                            id="kodePks"
                            class="w-full text-sm"
                            placeholder="Masukkan 3 digit kode PKS"
                            v-model="kodePks"
                            label="kode_pks"
                            required
                            @search="getDataDetailPks"
                            :options="listDataPks || []"
                            :clearable="false"
                          >
                            <template #spinner="{ loading }">
                              <div v-if="loading" class="vs__spinner"></div>
                            </template>
                            <template v-slot:option="option">
                              <div class="flex justify-between p-1">
                                {{ option.kode_pks }} |
                                {{ option.nomor_pks_mitra }}
                              </div>
                            </template>
                          </input-datalists>
                        </b-form-group>
                        <b-form-group
                          class="row pt-2"
                          label-cols-sm="2"
                          label="Nomor KS UT"
                          label-for="nomor_pks_ut"
                        >
                          <input-primary
                            id="nomor_pks_ut"
                            v-model="form.nomor_pks_ut"
                            required
                            isDetail
                          />
                        </b-form-group>
                        <b-form-group
                          class="row pt-2"
                          label-cols-sm="2"
                          label="Nomor KS Mitra"
                          label-for="nomor_pks_mitra"
                        >
                          <input-primary
                            id="nomor_pks_mitra"
                            v-model="form.nomor_pks_mitra"
                            required
                            isDetail
                          />
                        </b-form-group>
                        <b-form-group
                          class="row pt-2"
                          label-cols-sm="2"
                          label="Tipe Beasiswa"
                          label-for="tipe_beasiswa"
                        >
                          <input-primary
                            id="tipe_beasiswa"
                            v-model="form.tipe_beasiswa"
                            required
                            isDetail
                          />
                        </b-form-group>
                        <b-form-group
                          class="row pt-2"
                          label-cols-sm="2"
                          label="Beasiswa"
                          label-for="idBeasiswa"
                        >
                          <input-datalists
                            id="idBeasiswa"
                            class="w-full text-sm"
                            :placeholder="
                              form.idJenisProgram == null
                                ? 'Pilih program lebih dulu'
                                : 'Pilih Beasiswa'
                            "
                            v-model="form.idBeasiswa"
                            label="label"
                            required
                            :options="listDataBeasiswa || []"
                            :reduce="
                              listDataBeasiswa => listDataBeasiswa.id_beasiswa
                            "
                            :disabled="form.idJenisProgram == null"
                            :clearable="false"
                          >
                            <template v-slot:option="option">
                              <div class="flex justify-between p-1">
                                {{ option.label }}
                              </div>
                            </template>
                          </input-datalists>
                        </b-form-group>
                        <b-form-group
                          class="row pt-2"
                          label-cols-sm="2"
                          label="NIM Mahasiswa"
                          label-for="nim"
                        >
                          <input-primary
                            id="nim"
                            placeholder="Masukkan 9 digit NIM Mahasiswa"
                            v-model="form.nim"
                            required
                            @input="inputNim"
                            type="number"
                            max-length="9"
                            min-length="9"
                            isDetail
                          />
                        </b-form-group>
                        <b-form-group
                          class="row pt-2"
                          label-cols-sm="2"
                          label="Nama Mahasiswa"
                          label-for="namaMahasiswa"
                        >
                          <input-primary
                            id="namaMahasiswa"
                            v-model="form.namaMahasiswa"
                            required
                            isDetail
                          />
                        </b-form-group>
                        <b-row class="pt-5 justify-content-center">
                          <b-btn
                            class="btn-block w-25"
                            variant="primary"
                            @click="createData"
                            :disabled="!isValidFormPks"
                            ><i class="ri-save-line mr-1"></i>Simpan</b-btn
                          >
                        </b-row>
                      </b-form>
                    </b-tab>
                    <!-- ./END:Kerjasama -->
                    <!-- BEGIN:Surat Keputusan -->
                    <b-tab
                      title="Surat Keputusan"
                      title-link-class="font-weight-bold bg-light mx-2"
                    >
                      <b-form class="px-4 pt-2">
                        <b-form-group
                          class="row pt-2"
                          label-cols-sm="2"
                          label="Program"
                          label-for="idJenisProgram"
                        >
                          <b-form-radio
                            inline
                            id="program1"
                            v-model="form.idJenisProgram"
                            value="1"
                            name="idJenisProgram"
                            disabled
                            >Pendas</b-form-radio
                          >
                          <b-form-radio
                            inline
                            id="program2"
                            v-model="form.idJenisProgram"
                            value="2"
                            name="idJenisProgram"
                            disabled
                            >Non Pendas</b-form-radio
                          >
                          <b-form-radio
                            inline
                            id="program3"
                            v-model="form.idJenisProgram"
                            value="3"
                            name="idJenisProgram"
                            disabled
                            >PPS</b-form-radio
                          >
                        </b-form-group>
                        <b-form-group
                          class="row pt-2"
                          label-cols-sm="2"
                          label="Masa"
                          label-for="masa"
                        >
                          <input-primary
                            id="masa"
                            v-model="form.masa"
                            required
                            type="number"
                            max-length="5"
                          />
                        </b-form-group>
                        <b-form-group
                          class="row pt-2"
                          label-cols-sm="2"
                          label="Nomor SK Rektor"
                          label-for="nomorSkRektor"
                        >
                          <input-datalists
                            id="nomorSkRektor"
                            class="w-full text-sm"
                            placeholder="Masukkan 3 digit nomor SK Rektor"
                            v-model="nomorSkRektor"
                            label="label"
                            required
                            @search="getDataDetailSk"
                            :options="listDataSk || []"
                            :clearable="false"
                          >
                            <template #spinner="{ loading }">
                              <div v-if="loading" class="vs__spinner"></div>
                            </template>
                            <template v-slot:option="option">
                              <div class="flex justify-between p-1">
                                {{ option.label }} | {{ option.judul_sk }}
                              </div>
                            </template>
                          </input-datalists>
                        </b-form-group>
                        <b-form-group
                          class="row pt-2"
                          label-cols-sm="2"
                          label="Tanggal SK Rektor"
                          label-for="tgl_sk_rektor"
                        >
                          <flat-pickr
                            id="tgl_sk_rektor"
                            class="form-control bg-light cursor-not-allowed"
                            :config="basicConfig"
                            v-model="form.tgl_sk_rektor"
                            placeholder="Tanggal SK Rektor"
                            disabled
                          ></flat-pickr>
                        </b-form-group>
                        <b-form-group
                          class="row pt-2"
                          label-cols-sm="2"
                          label="Judul SK"
                          label-for="judul_sk"
                        >
                          <input-primary
                            id="judul_sk"
                            v-model="form.judul_sk"
                            required
                            isDetail
                          />
                        </b-form-group>
                        <b-form-group
                          class="row pt-2"
                          label-cols-sm="2"
                          label="Beasiswa"
                          label-for="idBeasiswa"
                        >
                          <input-datalists
                            id="idBeasiswa"
                            class="w-full text-sm"
                            :placeholder="
                              form.idJenisProgram == null
                                ? 'Pilih program lebih dulu'
                                : 'Pilih Beasiswa'
                            "
                            v-model="form.idBeasiswa"
                            label="label"
                            required
                            :options="listDataBeasiswa || []"
                            :reduce="
                              listDataBeasiswa => listDataBeasiswa.id_beasiswa
                            "
                            :disabled="form.idJenisProgram == null"
                            :clearable="false"
                          >
                            <template v-slot:option="option">
                              <div class="flex justify-between p-1">
                                {{ option.value }} | {{ option.label }}
                              </div>
                            </template>
                          </input-datalists>
                        </b-form-group>
                        <b-form-group
                          class="row pt-2"
                          label-cols-sm="2"
                          label="NIM Mahasiswa"
                          label-for="nim"
                        >
                          <input-primary
                            id="nim"
                            placeholder="Masukkan 9 digit NIM Mahasiswa"
                            v-model="form.nim"
                            required
                            @input="inputNim"
                            type="number"
                            max-length="9"
                            min-length="9"
                            isDetail
                          />
                        </b-form-group>
                        <b-form-group
                          class="row pt-2"
                          label-cols-sm="2"
                          label="Nama Mahasiswa"
                          label-for="namaMahasiswa"
                        >
                          <input-primary
                            id="namaMahasiswa"
                            v-model="form.namaMahasiswa"
                            required
                            isDetail
                          />
                        </b-form-group>
                        <b-row class="pt-5 justify-content-center">
                          <b-btn
                            class="btn-block w-25"
                            variant="primary"
                            @click="createData"
                            :disabled="!isValidFormSk"
                            ><i class="ri-save-line mr-1"></i>Simpan</b-btn
                          >
                        </b-row>
                      </b-form>
                    </b-tab>
                    <!-- ./END:Surat Keputusan -->
                  </b-tabs>
                </fieldset>
                <!-- ./END:Form Data -->
                <!-- BEGIN:Button -->
                <!-- <b-row class="pt-5 justify-content-center">
                  <b-button
                    class="btn-block w-25"
                    variant="primary"
                    type="button"
                    @click="createData"
                    :disabled="!isValidForm"
                    ><i class="ri-save-line"></i>Simpan</b-button
                  >
                </b-row> -->
                <!-- ./END:Button -->
              </b-form>
            </div>
            <!-- ./END:Form -->
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Body -->
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import Vue from 'vue'
import store from '@/store/index'
import { MENU_ROLE } from '@/config/featureStatus'

export default {
  name: 'menuKhusus.aktivasiSatuan',
  computed: {
    ...mapState('authService', ['token']),
    isValidFormPks() {
      const status =
        this.form.nobilling &&
        this.form.idJenisProgram &&
        this.form.masa &&
        this.kodePks &&
        // this.form.nomor_pks_ut &&
        // this.form.nomor_pks_mitra &&
        this.form.idBeasiswa &&
        this.form.nim &&
        this.form.namaMahasiswa !== null
      return status
    },
    isValidFormSk() {
      const status =
        this.form.nobilling &&
        this.form.idJenisProgram &&
        this.form.masa &&
        this.nomorSkRektor &&
        // this.form.tgl_sk_rektor &&
        // this.form.judul_sk &&
        this.form.idBeasiswa &&
        this.form.nim &&
        this.form.namaMahasiswa !== null
      return status
    }
  },
  async mounted() {
    this.innitData()
  },
  methods: {
    /**
     * Reset Value
     */
    resetInput() {
      /** Data Mahasiswa */
      this.form.nim = null
      this.form.namaMahasiswa = null
      this.form.upbjj = null
      this.form.programStudi = null
      this.form.masaRegistrasiAwal = null
      this.form.masaRegistrasiAkhir = null
      this.form.pendidikanAkhir = null
      this.form.statusDp = null
      /** Data Billing */
      this.form.statusBilling = null
      this.form.cabangBank = null
      this.form.totalSks = null
      this.form.ketStatusBank = null
      this.form.namaBank = null
      this.form.totalBayar = null
      this.form.tanggalSetor = null
      this.form.keterangan = null
    },
    resetInputPks() {
      this.kodePks = null
      this.form.nomor_pks_ut = null
      this.form.nomor_pks_mitra = null
      this.form.tipe_beasiswa = null
    },
    resetInputSk() {
      this.nomorSkRektor = null
      this.form.tgl_sk_rektor = null
      this.form.judul_sk = null
    },
    /**
     * Get data Entry Beasiswa
     */
    async getDataEntryBeasiswa() {
      this.isLoadingEntryBeasiswa = true
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/entryBeasiswa?kodeJenisProgram=${this.form.idJenisProgram}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listData = res.data?.data
          /** If listData exist ? Set the initial number of items : Set table message */
          this.listData.length > 0
            ? (this.totalRowsEntryBeasiswa = this.listData.length)
            : (this.table_message = 'Data tidak ditemukan')
          this.isLoadingEntryBeasiswa = false
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Get data list Beasiswa
     */
    async getDataListBeasiswa() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/entryBeasiswa/getBeasiswa?kodeJenisProgram=${this.form.idJenisProgram}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataBeasiswa = res.data.message
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Get data detail pks
     */
    getDataDetailPks(kodePks) {
      const result = axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/entryBeasiswa/getDetailPKS?dataPks=${kodePks}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataPks = res.data?.getDataPks
          if (this.kodePks) {
            this.form.nomor_pks_ut = this.kodePks.nomor_pks_ut
            this.form.nomor_pks_mitra = this.kodePks.nomor_pks_mitra
            this.form.tipe_beasiswa = this.kodePks.tipe_beasiswa
          } else {
            this.form.nomor_pks_ut = null
            this.form.nomor_pks_mitra = null
            this.form.tipe_beasiswa = null
          }
          this.resetInputSk()
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Get data detail sk rektor
     */
    getDataDetailSk(nomorSkRektor) {
      const result = axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/entryBeasiswa/getDetailSK?dataSK=${nomorSkRektor}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataSk = res.data?.getDataSk
          if (this.nomorSkRektor) {
            this.form.tgl_sk_rektor = this.nomorSkRektor.tgl_sk_rektor
            this.form.judul_sk = this.nomorSkRektor.judul_sk
          } else {
            this.form.tgl_sk_rektor = null
            this.form.judul_sk = null
          }
          this.resetInputPks()
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Get data nama mahasiswa
     */
    async inputNim() {
      if (this.form.nim && this.form.nim.length === 9) {
        const result = await axios({
          url: `${process.env.VUE_APP_URL_PUBLIC}/getNim?nim=${this.form.nim}`,
          method: 'GET',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + this.token
          }
        })
          .then(res => {
            this.listDataMahasiswa = res.data?.data
            if (this.listDataMahasiswa) {
              this.listDataMahasiswa.map(
                n => (this.form.namaMahasiswa = n.namaMahasiswa)
              )
            }
          })
          .catch(err => {
            console.log('ERROR', err)
            throw new Error(err)
          })
        return result
      } else {
        this.form.namaMahasiswa = null
      }
    },
    /**
     * Rules Input
     */
    /** Input Jenis Program */
    // inputJenisProgram () {
    //   /** Data Entry Beasiswa */
    //   this.getDataEntryBeasiswa(this.form.idJenisProgram)
    //   /** Dropdown Beasiswa */
    //   this.getDataListBeasiswa(this.form.idJenisProgram)
    // },
    /**
     * Search Data
     */
    async searchData() {
      this.resetInput()
      this.resetInputPks()
      this.resetInputSk()
      if (this.form.nobilling && this.form.nobilling.length === 20) {
        await axios({
          url: `${process.env.VUE_APP_URL_PUBLIC}/menuKhusus/getAngsuran?nobilling=${this.form.nobilling}`,
          method: 'GET',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + this.token
          }
        })
          .then(res => {
            const dataBilling = res.data?.data[0]
            this.form = { ...dataBilling }
            /** Data Entry Beasiswa */
            this.getDataEntryBeasiswa()
            /** Dropdown Beasiswa */
            this.getDataListBeasiswa()
            // custom form
            this.form.upbjj =
              dataBilling.kodeUpbjj + ' | ' + dataBilling.namaUpbjj
            this.form.programStudi =
              dataBilling.kodeProgramStudi +
              ' | ' +
              dataBilling.namaProgramStudi
            this.form.pendidikanAkhir =
              dataBilling.kodePendidikanAkhir +
              ' | ' +
              dataBilling.namaPendidikanAkhir
            this.form.statusDp =
              dataBilling.kodeStatusDp + ' | ' + dataBilling.keteranganStatusDp
            this.form.statusBilling =
              dataBilling.kodeStatusBilling +
              ' | ' +
              dataBilling.ketStatusBilling
            console.log('aaa ', res.data?.data[0])
          })
          .catch(err => {
            console.log('ERROR', err)
            this.$toast.error(err?.response?.data?.message)
            throw new Error(err)
          })
        /** Data Mahasiswa */
        // this.form.nim = '836420989'
        // this.form.namaMahasiswa = 'NURIL ANWAR'
        // this.form.upbjj = 'Bogor'
        // this.form.programStudi = 'PGSD - S1'
        // this.form.masaRegistrasiAwal = '20202'
        // this.form.masaRegistrasiAkhir = '20222'
        // this.form.pendidikanAkhir = 'SLTA'
        // this.form.statusDp = 'Aktif'
        // /** Data Billing */
        // this.form.statusBilling = 'Aktif'
        // this.form.cabangBank = '08521060:30027119'
        // this.form.totalSks = '15'
        // this.form.ketStatusBank = 'Lunas'
        // this.form.namaBank = 'BRI'
        // this.form.totalBayar = '1700000'
        // this.form.tanggalSetor = '2018-09-10'
        // this.form.keterangan = 'SPP PENDAS'
      } else {
        this.resetInput()
      }
    },
    /**
     * Create Data
     */
    async createData() {
      /** Get dasar beasiswa */
      if (this.kodePks == null) {
        this.dasarBeasiswa = 'SK'
      }
      if (this.nomorSkRektor == null) {
        this.dasarBeasiswa = 'PKS'
      }

      const bodyFormData = new FormData()
      bodyFormData.append('kodePks', this.kodePks.kode_pks)
      bodyFormData.append('dasarBeasiswa', this.dasarBeasiswa)
      bodyFormData.append('masa', this.form.masa)
      bodyFormData.append('nim', this.form.nim)
      bodyFormData.append('masaRegistrasiAwal', this.form.masaRegistrasiAwal)
      bodyFormData.append('masaKurikulum', this.form.masa)
      bodyFormData.append('idMhs', this.form.idMhs)
      bodyFormData.append('idBeasiswa', this.form.idBeasiswa)
      bodyFormData.append('nomorPksUt', this.kodePks.nomor_pks_ut)
      bodyFormData.append('nomorPksMitra', this.kodePks.nomor_pks_mitra)
      bodyFormData.append('nobilling', this.form.nobilling)
      bodyFormData.append('statusDp', this.form.kodeStatusDp)
      bodyFormData.append('statusBank', this.form.statusBank)

      await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/menuKhusus/simpan`,
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          console.log('ERROR', err)
          this.$toast.error(err?.response?.data?.message)
          throw new Error(err)
        })
    },
    /**
     * Init Data
     */
    async innitData() {
      /**
       * Get List Data
       */
      // await Promise.all([this.getDataKategoriPks_()])
    }
  },
  data() {
    return {
      idJenisProgram: null,
      kodePks: null,
      nomorSkRektor: null,
      dasarBeasiswa: null,
      // Date Picker
      basicConfig: {
        dateFormat: 'd-m-Y'
      },
      // Form
      form: {
        idJenisProgram: null,
        nobilling: null,
        /** Data Mahasiswa */
        idMhs: null,
        upbjj: null,
        programStudi: null,
        masaRegistrasiAwal: null,
        masaRegistrasiAkhir: null,
        pendidikanAkhir: null,
        statusDp: null,
        kodeStatusDp: null,
        /** Data Billing */
        statusBilling: null,
        cabangBank: null,
        totalSks: null,
        statusBank: null,
        ketStatusBank: null,
        namaBank: null,
        totalBayar: null,
        tanggalSetor: null,
        keterangan: null,
        /** PKS */
        masa: null,
        nomor_pks_ut: null,
        nomor_pks_mitra: null,
        tipe_beasiswa: null,
        idBeasiswa: null,
        nim: null,
        namaMahasiswa: null,
        /** Surat Keputusan */
        tgl_sk_rektor: null,
        judul_sk: null
      },
      // Data List
      listDataBeasiswa: [],
      listDataPks: [],
      listDataSk: []
    }
  },
  beforeRouteEnter(to, from, next) {
    const idUnitKs = store.state.authService.idUnitKs
    if (
      MENU_ROLE.MENU_KHUSUS_AKTIVASI_BILLING_SATUAN.includes(String(idUnitKs))
    ) {
      return next()
    } else {
      Vue.$toast.error('Anda tidak memiliki hak akses')
      return next({ name: 'sidebar.dashboard' })
    }
  }
}
</script>
